import configAmplify from './amplify';
import * as Sentry from '@sentry/browser';
import * as config from './config';
import log from 'loglevel';

const ENV_CONFIG = config.get();

export const onClientEntry = () => {
  const isLocal = window.location.hostname === 'localhost';

  if (isLocal) {
    log.setDefaultLevel('debug');
  } else {
    log.setDefaultLevel(ENV_CONFIG.logLevel);
  }
  configAmplify();
  if (!isLocal) {
    log.debug('initting sentry');
    Sentry.init({
      dsn: ENV_CONFIG.sentryDSN,
      // integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      release: process.env.GATSBY_RELEASE_SHA,
    });
  } else {
    log.info('omitting sentry for localhost');
  }
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt to install the PWA
    log.debug('omitting the PWA prompt');
    e.preventDefault();
  });
};

export const onRouteUpdate = ({ location, prevLocation }, ctx) => {
  const currentVersion = process.env.GATSBY_RELEASE_SHA;
  const latestVersion = window.ACORN_LATEST_VERSION;
  log.debug({ location, prevLocation, latestVersion, currentVersion }, 'got onRouteUpdate');
  if (location.pathname.includes('/app/admin') && latestVersion && latestVersion !== currentVersion) {
    log.warn({ latestVersion, currentVersion }, 'new version detected, reloading page');
    window.location.reload();
  }
};
