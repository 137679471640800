exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app/[...].tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-templates-in-stock-tsx": () => import("./../../../src/templates/in-stock.tsx" /* webpackChunkName: "component---src-templates-in-stock-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-page-with-toc-tsx": () => import("./../../../src/templates/page_with_toc.tsx" /* webpackChunkName: "component---src-templates-page-with-toc-tsx" */),
  "component---src-templates-product-category-tsx": () => import("./../../../src/templates/product-category.tsx" /* webpackChunkName: "component---src-templates-product-category-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

