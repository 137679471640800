const DEV_CDN_URL = 'https://www.dev.plugtrays.com';
const DEV_API_URL = 'https://api.dev.plugtrays.com';
const DEV_API_GATEWAY_URL = 'https://f8j0jtasz7.execute-api.us-west-2.amazonaws.com';

const PROD_CDN_URL = 'https://www.plugtrays.com';
const PROD_API_URL = 'https://api.plugtrays.com';
const PROD_API_GATEWAY_URL = 'https://vxoj24wkji.execute-api.us-west-2.amazonaws.com';

const ENVIRONMENT_CONFIG = {
  local: {},
  dev: {
    region: 'us-west-2',
    authorizeAcceptJsUrl: 'https://jstest.authorize.net/v1/Accept.js',
    authorizeNetApiLoginId: '7kaL2RS9rpm',
    authorizeNetClientKey: '5S2ysQ493FaV3mc43xa2V2MkCTAH763Tw3a2FH7mss4FVyaXm6aRerJEmBCNkS72',
    logLevel: 'warn',
    sentryDSN: 'https://0a9a2c77cef74f0a954f3b24c31c3018@sentry.io/2020577',
    productImageSlugPath: 'https://test:fortestingonly@www.dev.plugtrays.com/organic-plant-images/',
    mvgSiteUrl: 'https://www.dev.mountainvalleygrowers.com',
    siteUrl: DEV_CDN_URL,
    graphqlBuildApi: `${DEV_API_GATEWAY_URL}/apiV0/graphql-build`,
    publicApi: `${DEV_CDN_URL}/apiV0/public`,
    graphqlBuildApiKey: 'Iy487AwiCz8E7eMUuLjaqj06vZPW1343v7QcELG5',
    cognitoDomain: 'users.acorn.dev.plugtrays.com',
    graphqlApi: `${DEV_API_URL}/graphql`,
    graphqlApiCdn: `${DEV_CDN_URL}/apiV0/graphql`,
    grahpqlApiCdnSigningHost: `${DEV_API_GATEWAY_URL}/apiV0/graphql`,
    cognitoUserPoolId: 'us-west-2_XGvZNrzXa',
    cognitoWebClientId: '5o8hqhp50q6g1dfgbsvkqe4agu',
    cognitoIdentityPoolId: 'us-west-2:eac2caca-d7a7-47d4-bfcb-b8f0527074d5',
    gaTrackingId: 'UA-155171289-1',
    warrantyStatesUrl: `${DEV_CDN_URL}/warranty-states.json`,
    robotsPolicy: [{ userAgent: '*', disallow: ['/'] }],
    enableGiftCards: true,
    imageCdnPrefix: `${DEV_CDN_URL}/organic-plants-assets`,
    quickbooksClientId: 'AB3Uw15bHEnrAZ6xPYXNcA1TZN8vlMsQHbFyixoYxcFriGZg1S'
  },
  prod: {
    region: 'us-west-2',
    authorizeAcceptJsUrl: 'https://js.authorize.net/v1/Accept.js',
    authorizeNetApiLoginId: '7rs9K9Y7h9',
    authorizeNetClientKey: '7zcHUyhcY229U9p6j5vMcbWKm545H3s7VGjX8UR8XGwtjF6ZaE74dmWwZuY8WHm5',
    logLevel: 'warn',
    sentryDSN: 'https://289bb1aa091648e885493839c9efb52e@o517456.ingest.sentry.io/5625303',
    mvgSiteUrl: 'https://www.mountainvalleygrowers.com',
    siteUrl: PROD_CDN_URL,
    graphqlBuildApi: `${PROD_API_GATEWAY_URL}/apiV0/graphql-build`,
    publicApi: `${PROD_CDN_URL}/apiV0/public`,
    graphqlBuildApiKey: 'dsZULNYObf3cl4EZlxIPb3RWrDTbW6kJ7agHGzx5',
    cognitoDomain: 'users.plugtrays.com',
    graphqlApi: `${PROD_API_URL}/graphql`,
    graphqlApiCdn: `${PROD_CDN_URL}/apiV0/graphql`,
    grahpqlApiCdnSigningHost: `${PROD_API_GATEWAY_URL}/apiV0/graphql`,
    cognitoUserPoolId: 'us-west-2_zgItoCJTT',
    cognitoWebClientId: '7favv9pjjtrkodhcj2vtjbl6h6',
    cognitoIdentityPoolId: 'us-west-2:3fcd83c7-a4ba-4518-8faf-3ee6dce834a0',
    gaTrackingId: 'UA-155056885-1',
    warrantyStatesUrl: `${PROD_CDN_URL}/warranty-states.json`,
    robotsPolicy: [{ userAgent: '*', allow: '/' }],
    enableGiftCards: false,
    imageCdnPrefix: `${PROD_CDN_URL}/organic-plants-assets`,
    quickbooksClientId: 'ABsNtmKvl9Hr0wGfPoG82IUYKfy4WneFWhK97JeiRMTrqtjn6V',
  },
};

const getEnvironment = () => process.env.GATSBY_ACORN_ENVIRONMENT || 'dev';

const get = () => {
  const env = process.env.GATSBY_ACORN_ENVIRONMENT || 'dev';
  return ENVIRONMENT_CONFIG[env];
};

const getForEnv = (env) => ENVIRONMENT_CONFIG[env || getEnvironment()];

const isDev = () => {
  const env = process.env.GATSBY_ACORN_ENVIRONMENT || 'dev';
  return env === 'dev';
};

module.exports = {
  // eslint-disable-line
  get,
  isDev,
  getEnvironment,
  getForEnv,
};
